<template>
  <div>
    <nav-bar/>
    <div>
      <van-cell center>
        <template #title>
          <p style="font-size: 18px">
            <span>收入：{{ keepDeclare.incomeMoney != null ? keepDeclare.incomeMoney : 0 }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>成本：{{ keepDeclare.costMoney != null ? keepDeclare.costMoney : 0 }}</span>
          </p>
        </template>
        <template #label>
          <div>
            <p>纳税：{{ keepDeclare.taxMoney != null ? keepDeclare.taxMoney : 0 }}</p>
            <p>状态：
              <van-tag plain type="primary" v-if="keepDeclare.status === 2"> 审核中</van-tag>
              <van-tag plain type="danger" v-if="keepDeclare.status === 0"> 已驳回</van-tag>
              <van-tag plain type="success" v-if="keepDeclare.status === 1"> 已纳税</van-tag>
            </p>
            <p>时间：{{ keepDeclare.createdAt }}</p>
            <p v-if="keepDeclare.status === 0">驳回原因: <span style="color: #e33d33">{{ keepDeclare.rejectReason }}</span>
            </p>
            <p v-if="keepDeclare.status === 1">发票：
              <van-image
                  width="100"
                  height="100"
                  v-if="keepDeclare.invoiceUrl"
                  :src=keepDeclare.invoiceUrl
                  @click="imagePreview(0)"
              />
            </p>
            <p v-if="keepDeclare.status === 1">提示：
              <span style="color: #e33d33">汇总开票的名称存在非本人姓名情况</span>
            </p>
          </div>
        </template>
      </van-cell>
      <div style="margin: 20px">
        <van-button type="warning" block round v-if="keepDeclare.status === 0"
                    @click="updKeepDeclare(1,keepDeclare.id)"
                    :loading="loginLoading" loading-text="提交中...">
          重新提交
        </van-button>
        <van-button type="danger" block round v-if="keepDeclare.status === 2"
                    @click="updKeepDeclare(0,keepDeclare.id)"
                    :loading="loginLoading" loading-text="提交中...">
          撤销申请
        </van-button>
      </div>
    </div>
    <div>
      <div v-for="(item1,index) in keepDeclares" :key="index" style="margin-top: 10px">
        <van-cell-group>
          <van-cell>
            <template #title>
              {{ item1.keepMTime }}
            </template>
          </van-cell>
          <van-cell v-for="(item2,index2) in item1.list" :key="index2" center>
            <template #title>
              <strong style="font-size: 18px">
                &nbsp;&nbsp;{{ item2.feeTypeName }}
              </strong>
            </template>
            <template #default>
              <p style="color: #333333">
                <span v-if="item2.paymentType === 0">
                &nbsp;&nbsp;+{{ item2.money }}
              </span>
                <span v-else style="color: #e33d33">
                &nbsp;&nbsp;-{{ item2.money }}
              </span>
              </p>
            </template>
          </van-cell>
        </van-cell-group>
      </div>

    </div>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import {getKeepDeclare, updKeepDeclare} from "@/api/declare/declare";
import {ImagePreview} from "vant";
import VantMessage from "@/utils/vantMessageUtils";
import {remove} from "@/utils/authUtils";

export default {
  name: "declarationDetails",
  components: {
    NavBar
  },
  data() {
    return {
      loginLoading:false,
      keepDeclare: [],
      keepDeclares: [],
    }
  },
  methods: {
    async getKeepDeclare(keepDeclareId) {
      let res = await getKeepDeclare({keepDeclareId});
      if (res) {
        this.keepDeclare = res.keepDeclare
        this.keepDeclares = res.list
      }
    },
    imagePreview(index) {
      let imgFile = [];
      imgFile.push(this.keepDeclare.invoiceUrl);
      ImagePreview({
        images: imgFile,
        startPosition: index,
      });
    },
    updKeepDeclare(keepDeclareType, keepDeclareId) {
      let text = keepDeclareType === 0?'你确定要撤销申请吗？':'你确定要重新提交吗？'
      VantMessage.confirmMsg(text).then(async resolve => {
        if (resolve) {
          this.loginLoading = true;
          let res = await updKeepDeclare({
            keepDeclareType,
            keepDeclareId
          })
          if (res) {
            VantMessage.successMsg("操作成功!")
            await this.$router.replace("/taxPayment");
          }
        }
      })

    }
  },
  mounted() {
    this.getKeepDeclare(this.$route.query.keepDeclareId)
    document.title = this.$route.meta.title
  }
}
</script>

<style scoped>

</style>
